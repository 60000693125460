<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <div class="d-flex" style="align-items: center;">
              <i class="fa fa-solid fa fa-bullhorn " aria-hidden="true" style="font-size: 24px; margin-right: 3px; color: var(--iq-primary);"></i>
              <h4 class="card-title ml-2">{{cvCardTitle}}</h4>
            </div>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="customFormFieldsAdd">{{cvbtnAddNew}}</b-button>
            <b-button class="ml-4" variant="primary" @click="goToForms">{{cvbtnForms}}</b-button>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <!-- Frontend Search -->
              <!-- <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>Frontend Search -->

              <!-- Backend Search -->
              <b-col class="col-12 col-sm-8 col-md-5 col-lg-4 mb-2">
                <b-form-input class="form-control"
                  @input="searchcustomFormFieldsWithParams"
                  v-model="search_param"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col><!-- Backend Search -->
              <b-col sm="8" md="8" lg="8" xl="8" class="pr-0 mb-3">
                <div class="dateRange">
                  <date-range-picker ref="picker" class="w-100"  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"  v-model="whereFilter.dateRange"  @update="getcustomFormFieldsDateWise()" ></date-range-picker>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="customFormFieldsObjList && customFormFieldsObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="customFormFieldsObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentBatchNo"
                  :per-page="dataPerPage"
                >
                  <template v-slot:cell(cff_id)="data">
                    <span>{{data.item.cff_id.substring(0, 8)+"..."}}&nbsp;<i class="fa-solid fa-copy pointer" title="Copy Cff Id" @click="doCopy(data.item.cff_id)"></i></span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="customFormFieldsEdit('MODAL', data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-danger" @click="showCustomFormFieldsDeleteDialog(data.item)" size="sm"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-col sm="12" md="12" class="my-1">
                <b-row>
                  <b-col sm="12" md="7">
                    <b-btn variant="primary" @click="loadMoreData" v-if="showLoadMoreBtn">Load More</b-btn>
                  </b-col>
                  <b-col sm="5" md="5">
                    <b-pagination
                      v-model="currentBatchNo"
                      :total-rows="totalRows"
                      :per-page="dataPerPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </b-col>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal v-model="showModelCustomFormFieldsDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelCustomFormFieldsDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="customFormFieldsDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <!-- Form Field Add -->
    <b-modal
      v-model="showModelCustomFormFieldsAdd"
      scrollable
      :title="cvAddModalHeader"
    >
      <CustomFormFieldsAdd :propOpenedInModal="true" @emitCloseCustomFormFieldsAddModal="closeCustomFormFieldsAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="customFormFieldsAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeCustomFormFieldsAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal><!-- Form Field Add -->

    <!-- Form Field Edit -->
    <b-modal
      v-model="showModelCustomFormFieldsEdit"
      scrollable
      :title="cvEditModalHeader"
    >
      <CustomFormFieldsEdit :propOpenedInModal="true" :propCustomFormFieldsObj="customFormFieldsEditObj" @emitCloseCustomFormFieldsEditModal="closeCustomFormFieldsEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="customFormFieldsEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeCustomFormFieldsEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal><!-- Form Field Edit -->

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { CustomFormFieldss } from "../../../FackApi/api/CustomFormFields.js"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit.js"
import CustomFormFieldsAdd from "./CustomFormFieldsAdd"
import CustomFormFieldsEdit from "./CustomFormFieldsEdit.vue"

export default {
  name: "CustomFormFieldsList",
  components: {
    CustomFormFieldsAdd,
    CustomFormFieldsEdit
  },
  data () {
    return {
      cvCardTitle: "Custom Form Fields",
      cvbtnAddNew: "Add New",
      cvbtnForms: "Forms",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit Custom Form Fields",
      cvAddModalHeader: "Add Custom Form Fields",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "CustomFormFields",
      showModelCustomFormFieldsAdd: false,
      showModelCustomFormFieldsEdit: false,
      showModelCustomFormFieldsDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "Cff Id ", key: "cff_id", class: "text-left align-text-top", sortable: true },
        { label: "form field type", key: "form_field_type", class: "text-left align-text-top", sortable: true },
        { label: "form field label", key: "form_field_label", class: "text-left align-text-top", sortable: true },
        { label: "form field variable", key: "form_field_variable", class: "text-left align-text-top", sortable: true },
        { label: "form field data type", key: "form_field_data_type", class: "text-left align-text-top", sortable: true },
        { label: "Action ", key: "action", class: "text-left align-text-top", sortable: false }
      ],
      customFormFieldsObjList: [],
      customFormFieldsEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null,
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 365)), // From Last Year
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        }
      },
      search_param: "",
      currentBatchNo: 1,
      dataPerPage: 25,
      showLoadMoreBtn: true
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    dateFilter () {
      return { ...this.$store.getters["DateFilter/selectedDateState"] }
    }
  },
  async mounted () {
    await this.getDateFilter()
    socialvue.index()
    this.customFormFieldsList()
  },
  methods: {
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
    */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter = storeDate
      }
    },
    /**
     * searchcustomFormFieldsWithParams
     */
    async searchcustomFormFieldsWithParams () {
      if (this.whereFilter.search_param.length == 0) {
        this.showLoadMoreBtn = true
      }
      this.currentBatchNo = 1
      this.customFormFieldsList()
    },
    /**
     * loadMoreData
     */
    async loadMoreData () {
      this.currentBatchNo = this.currentBatchNo + 1
      this.customFormFieldsList(true)
    },
    /**
     * Calling Function as per whereFilter
    */
    async getcustomFormFieldsDateWise () {
      this.customFormFieldsList()
      await this.setDateFilter()
    },
    /**
     * Setting Date Range in the LS
     */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     * customFormFieldsList
     */
    async customFormFieldsList (loadViaLoadMore = false) {
      try {
        // setting data for pagination
        this.whereFilter.currentBatchNo = this.currentBatchNo
        this.whereFilter.dataPerPage = this.dataPerPage
        this.whereFilter.search_param = this.search_param

        let customFormFieldsListResp = await CustomFormFieldss.customFormFieldsList(this, this.whereFilter)
        if (customFormFieldsListResp.resp_status) {
          if (this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
            this.customFormFieldsObjList = [ ...customFormFieldsListResp.resp_data.data ]
          }
          else {
            this.customFormFieldsObjList = [ ...this.customFormFieldsObjList, ...customFormFieldsListResp.resp_data.data ]
          }
          this.showLoadMoreBtn = true

          // Adding the serial numbers:
          this.customFormFieldsObjList.forEach((s, index) => { s.id = index + 1 })
          this.totalRows = this.customFormFieldsObjList.length
        }
        else {
          this.showLoadMoreBtn = false
          this.currentBatchNo = this.currentBatchNo - 1
          this.toastMsg = customFormFieldsListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at customFormFieldsList() and Exception:", err)
      }
    },
    /**
     * customFormFieldsAdd
     */
    customFormFieldsAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/customFormFields_add")
        }
        else {
          this.showModelCustomFormFieldsAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at customFormFieldsAdd() and Exception:", err.message)
      }
    },
    /**
     * formatJSON
     */
    formatJSON (unformattedJSON = null) {
      try {
        if (!unformattedJSON) {
          unformattedJSON = document.getElementById("json").value
        }
        let obj = JSON.parse(unformattedJSON)
        let formattedJSON = JSON.stringify(obj, undefined, 4)
        this.customFormFieldsEditObj.form_dropdown_structure = formattedJSON
      }
      catch (err) {
        this.toastMsg = "Invalid form_config json set in database"
        this.toastVariant = "danger"
        this.showToast = true
      }
    },
    /**
     * goToForms
     */
    goToForms () {
      window.open("/customFormBuild_list")
    },
    /**
     * customFormFieldsEdit
     */
    customFormFieldsEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/customFormFields_edit/" + this.customFormFieldsEditObj.cff_id)
        }
        else {
          this.customFormFieldsEditObj = item
          this.formatJSON(this.customFormFieldsEditObj.form_dropdown_structure)
          console.log("PPp ", this.customFormFieldsEditObj)
          this.showModelCustomFormFieldsEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at customFormFieldsEdit() and Exception:", err.message)
      }
    },
    /**
     * showCustomFormFieldsDeleteDialog
     */
    showCustomFormFieldsDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelCustomFormFieldsDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showCustomFormFieldsDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * customFormFieldsDelete
     */
    async customFormFieldsDelete () {
      try {
        let customFormFieldsDelResp = await CustomFormFieldss.customFormFieldsDelete(this, this.delObj.cff_id)
        await ApiResponse.responseMessageDisplay(this, customFormFieldsDelResp)

        if (customFormFieldsDelResp && !customFormFieldsDelResp) {
          this.showModelCustomFormFieldsDelete = false
          return false
        }

        let index = this.customFormFieldsObjList.indexOf(this.delObj)
        this.customFormFieldsObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelCustomFormFieldsDelete = false
      }
      catch (err) {
        console.error("Exception occurred at customFormFieldsDelete() and Exception:", err.message)
      }
    },
    /**
     * closeCustomFormFieldsAddModal
     */
    closeCustomFormFieldsAddModal (customFormFieldsAddData) {
      try {
        if (customFormFieldsAddData) {
          if (this.customFormFieldsObjList && this.customFormFieldsObjList.length >= 1) {
            let customFormFieldsObjLength = this.customFormFieldsObjList.length
            let lastId = this.customFormFieldsObjList[customFormFieldsObjLength - 1]["id"]
            customFormFieldsAddData.id = lastId + 1
          }
          else {
            this.customFormFieldsObjList = []
            customFormFieldsAddData.id = 11111
          }

          customFormFieldsAddData.created_on = moment()
          this.customFormFieldsObjList.unshift(customFormFieldsAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelCustomFormFieldsAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closecustomFormFieldsAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeCustomFormFieldsEditModal
     */
    closeCustomFormFieldsEditModal () {
      try {
        this.showModelCustomFormFieldsEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeCustomFormFieldsEditModal() and Exception:", err.message)
      }
    },
    /**
     *  Do Copy
     */
    doCopy: function (msg) {
      var dummy = document.createElement("textarea")
      document.body.appendChild(dummy)
      dummy.value = msg
      dummy.select()
      document.execCommand("copy")
      document.body.removeChild(dummy)
    }
  }
}
</script>
