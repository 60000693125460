/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <form action="#">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationform_field_label">
                    {{cvFormFieldLabelLabel}}</label
                  >
                  <input
                    v-model="vmCustomFormFieldsFormData.form_field_label"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationform_field_variable">
                    {{cvFormFieldVariableLabel}}</label
                  >
                  <input
                    v-model="vmCustomFormFieldsFormData.form_field_variable"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3">
                    <label for="validationform_field_type">
                      {{cvFormFieldTypeLabel}}
                      <p class="primary-color">Allowed Values:&nbsp;[INPUT,&nbsp;DROPDOWN]</p>
                    </label>
                    <input
                      v-model="vmCustomFormFieldsFormData.form_field_type"
                      type="text"
                      class="form-control"
                      required
                    />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validationform_field_data_type">
                    {{cvFormFieldDataTypeLabel}}
                  </label>
                  <p class="primary-color">Allowed Values:&nbsp;[varchat(128)]</p>
                  <input
                    v-model="vmCustomFormFieldsFormData.form_field_data_type"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <!-- Is DropDown checkbox -->
                <div class="col-md-12 mb-3">
                  <input v-model="isDropDown" type="checkbox" id="isDropDown" style="margin-right:5px" />
                  <label for="isDropDown" style="color:red">Field Is Drop Down</label>
                </div><!-- Is DropDown checkbox -->

                <!-- Data Schema(only for JSON) -->
                <div class="col-md-12 mb-3" v-if="isDropDown">
                  <label for="validationdata_schema">
                    {{cvDataSchemaLabel}}</label
                  >
                  <textarea :placeholder='JSON.stringify([{"id": 1,"name": "Choice 1"},{"id": 2,"name": "Choice 2"}], undefined, 4)' v-model="vmCustomFormFieldsFormData.dropdown_structure" type="json" @input="formatJSON" class="form-control textarea" id="json">
                  </textarea>
                  <span class="primary-color" v-if="invalidJSONMsg">{{invalidJSONMsg}}</span>
                </div><!-- Data Schema(only for JSON) -->
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="customFormFieldsAdd()"
                >
                  {{ cvSubmitBtn }}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle"
    >
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { CustomFormFieldss } from "../../../FackApi/api/CustomFormFields"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"

export default {
  name: "CustomFormFieldsAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      cvCardTitle: "Add Custom Form Fields",
      cvSubmitBtn: "Add",
      cvFormFieldTypeLabel: "form html element(BLOCK LETTERS ONLY)",
      cvFormFieldLabelLabel: "form field label",
      cvFormFieldVariableLabel: "form field variable",
      cvFormFieldDataTypeLabel: "form field data type",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "CustomFormFields Added",
      vmCustomFormFieldsFormData: Object.assign({}, this.initFormData()),
      vmCustomFormFieldsDesc: null,
      vmCustomFormFieldsType: null,
      isDropDown: false,
      cvDataSchemaLabel: "Drop Down Field Structure",
      invalidJSONMsg: null
    }
  },
  mounted () {
    socialvue.index()
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "form_field_name": "",
        "form_field_type": "",
        "form_field_label": "",
        "form_field_variable": "",
        "form_field_data_type": "",
        "deleted": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmCustomFormFieldsFormData) {
          if (!this.vmCustomFormFieldsFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * formatJSON
     */
    formatJSON () {
      try {
        let unformattedJSON = document.getElementById("json").value
        let obj = JSON.parse(unformattedJSON)
        if (obj) {
          this.invalidJSONMsg = false
        }
        let formattedJSON = JSON.stringify(obj, undefined, 4)
        this.vmCustomFormFieldsFormData.dropdown_structure = formattedJSON
      }
      catch (err) {
        this.invalidJSONMsg = "Invalid JSON"
      }
    },
    /**
     * customFormFieldsAdd
     */
    async customFormFieldsAdd () {
      if (this.invalidJSONMsg) {
        this.toastMsg = "Invalid Json in dropdown structure field"
        this.toastVariant = "danger"
        this.showToast = true
        return
      }
      try {
        this.cvLoadingStatus = true
        let customFormFieldsAddResp = await CustomFormFieldss.customFormFieldsAdd(this, this.vmCustomFormFieldsFormData)
        await ApiResponse.responseMessageDisplay(this, customFormFieldsAddResp)
        if (customFormFieldsAddResp && !customFormFieldsAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseCustomFormFieldsAddModal", customFormFieldsAddResp.resp_data)
        }
        this.vmCustomFormFieldsFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at customFormFieldsAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
<style scoped>
.textarea{
    height: 195px !important;
    overflow-y: scroll;
    line-height: 18px !important;
  }
</style>
